import { Outlet, Route } from 'react-router-dom'
import { RouteInfo, SiteMap } from 'interface/route'
import MainLayout from 'layouts/MainLayout'
import DashboardView from 'pages/Dashboard'
import CompanyInfoView from 'pages/CompanyInfo'
import LicenseApplicationsView from 'pages/LicenseApplications'
import RequirementsView from 'pages/Requirements'
import SalesRepresentativesView from 'pages/SalesRepresentatives'
import ProfileView from 'pages/Profile'

export const mainLayoutRoute: RouteInfo = {
  key: 'Root',
  label: 'Root',
  path: '/',
  element: (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ),
  children: {
    dashboard: {
      key: 'Dashboard',
      label: 'Dashboard',
      path: '/',
      element: <DashboardView />,
      defaultPath: '/',
      trackingName: 'Dashboard'
    },
    companyInfo: {
      key: 'CompanyInfo',
      label: 'Company Info',
      path: '/company-info',
      element: <CompanyInfoView />,
      defaultPath: '/company-info',
      trackingName: 'Company Info'
    },
    licenseApplications: {
      key: 'LicenseApplications',
      label: 'License Applications',
      path: '/license-applications',
      element: <LicenseApplicationsView />,
      defaultPath: '/license-applications',
      trackingName: 'License Applications'
    },
    requirements: {
      key: 'Requirements',
      label: 'Requirements',
      path: '/requirements',
      element: <RequirementsView />,
      defaultPath: '/requirements',
      trackingName: 'Requirements'
    },
    salesRepresentatives: {
      key: 'SalesRepresentatives',
      label: 'Sales Representatives',
      path: '/sales-representatives',
      element: <SalesRepresentativesView />,
      defaultPath: '/sales-representatives',
      trackingName: 'Sales Representatives'
    },
    profile: {
      key: 'Profile',
      label: 'Profile',
      path: '/profile',
      element: <ProfileView className="mt-[-47px]" />,
      defaultPath: '/profile',
      trackingName: 'Profile'
    },
  }
}

export const renderRoute = (route: RouteInfo) => (
  <Route key={route.key} path={route.path} element={route.element}>
    {route?.children && renderSiteMap(route.children)}
  </Route>
)

export const renderSiteMap = (siteMap: SiteMap) => Object.values(siteMap).map(route => renderRoute(route))
