import { FC, useState, useEffect } from 'react'

import { Button, Dropdown, DropdownItem, AptiveIcon } from '@aptive-env/storybook'
import styles from './index.module.scss'

interface Options {
  [key: string]: {
    name: string;
    items?: string[];
    abbr?: string[];
  }
}

interface FiltersProps {
  values?: Record<string, string>
  options: Options
  onChange: (filter: Record<string, string>) => void
  onDownload: () => void
}

const Filters: FC<FiltersProps> = ({ values, options, onChange, onDownload }) => { 
  const [filterValues, setFilterValues] = useState<Record<string, string>>({})
  const [dropdownsOpen, setDropdownsOpen] = useState<Record<string, boolean>>({})
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({})

  useEffect(() => {
    const tempFilterValues: Record<string, string> = {}
    const tempDropdownsOpen: Record<string, boolean> = {}

    Object.keys(options).forEach((key) => {
      if (options[key].items) {
        tempFilterValues[key] = ''
        tempDropdownsOpen[key] = false
      }
    })

    setFilterValues(tempFilterValues)
    setDropdownsOpen(tempDropdownsOpen)
  }, [options])


  useEffect(() => {
    setFilterValues({...filterValues, ...values})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const handleDropdownToggle = (key: string, value?: boolean) => {
    const tempDropdownsOpen = { ...dropdownsOpen }
    Object.keys(tempDropdownsOpen).forEach((dropdownKey) => {
      tempDropdownsOpen[dropdownKey] = dropdownKey === key ? value ?? !tempDropdownsOpen[dropdownKey] : false
    })

    setDropdownsOpen(tempDropdownsOpen)
  }

  const onSetFilter = (key: string, item: string, value?: string) => {
    const tempFilterValues = { ...filterValues }
    tempFilterValues[key] = tempFilterValues[key] === value ? '' : value ?? item
    setFilterValues(tempFilterValues)
    setSelectedFilters({ ...selectedFilters, [key]: item })
    onChange(tempFilterValues)
  }

  const handleButtonClick = (key: string) => {
    if (key === 'download') {
      onDownload()
    }
  }

  return (
    <div className="flex items-center gap-4">
      {Object.keys(options).map((key) => (
        <div key={key} className="relative z-10">
          {options[key].items
            ? (
              <>
                <Button
                  className={`w-full bg-white border border-gray-200 text-xs !py-1 !px-2 !rounded-2xl ${styles.dropdownButton}`}
                  icon="chevronDown"
                  position="right"
                  variant="neutral"
                  onClick={(event) => {
                    event.stopPropagation()
                    handleDropdownToggle(key)
                  }}
                  isFilled
                  aria-label="Dropdown button"
                  aria-haspopup="true"
                >
                  <span className="text-nowrap">{selectedFilters[key] ? `${options[key].name}: ${selectedFilters[key]}` : options[key].name}</span>
                </Button>
                <Dropdown 
                  isOpen={dropdownsOpen[key]} 
                  onClose={() => handleDropdownToggle(key, false)} 
                  defaultValue={filterValues[key]} 
                  className="w-full right-0 max-h-[350px] overflow-y-auto"
                >
                  {options[key].items?.sort().map((item, index) => (
                    <DropdownItem key={item} selected={filterValues[key] === item} onClick={() => onSetFilter(key, item, options[key]?.abbr?.[index])}>
                      {item}
                    </DropdownItem> 
                  ))}
                </Dropdown>
              </>
            )
            : (
              <Button
                className="flex items-center bg-white border border-gray-200 text-xs !py-1 !px-2 !rounded-2xl"
                variant="neutral"
                isFilled
                onClick={() => handleButtonClick(key)}
              >
                {key === 'filters' && (
                  <AptiveIcon
                    className="w-[12px] h-[12px] fill-[#4B5563] stroke-none mr-1"
                    icon="adjustments"
                    isFilled
                  />
                )}
                {key === 'download' && (
                  <AptiveIcon
                    className="w-[16px] h-[16px] stroke-[#4B5563] fill-none mr-1"
                    icon="documentDownload"
                  />
                )}
                <span>{options[key].name}</span>
              </Button>
            )}
        </div>
      ))}
    </div>
  )
}

export default Filters
