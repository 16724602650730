import { FC, useState, useEffect, useMemo } from 'react'
import { AptiveIcon } from '@aptive-env/storybook'
import { capitalize } from 'lodash'

import Filters from 'components/Filters'
import TableHeader from 'components/TableHeader'
import TableFooter from 'components/TableFooter'
import { PaperClipSVG } from 'icons'
import ChecklistModal from './ChecklistModal'
import DeleteChecklistModal from './DeleteChecklist'
import { fetchChecklistAttachments, fetchChecklists } from 'services/checklist'
import { State, Season, Checklist, Attachment } from 'interface'
import {
  fetchStates,
  fetchSeasons
} from 'services'
import Direction from 'components/Direction'
import moment from 'moment'
import { formatDate } from 'lib/utils'
import { useToast } from 'hooks/useToast'

const tHeadClassName = 'flex-1 relative flex items-center gap-2 cursor-pointer text-xs uppercase tracking-[0.03em] px-4 py-3 text-gray-600 hover:text-gray-950 [&_svg]:hover:stroke-[#030712]'

const RequirementsView: FC = () => {
  const { setToast } = useToast()
  const [seasons, setSeasons] = useState<Season[]>([])
  const [states, setStates] = useState<State[]>([])
  const [checklists, setChecklists] = useState<Checklist[]>([])
  const [checklistsTotal, setChecklistsTotal] = useState(0)
  const [filter, setFilter] = useState<Record<string, string>>({})
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState({ name: '', direction: 1 })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [isRequirementModalOpen, setIsRequirementModalOpen] = useState(false)
  const [selectedChecklist, setSelectedChecklist] = useState<Checklist | null>(null)
  const [selectedChecklistAttachments, setSelectedChecklistAttachments] = useState<Attachment[]>([])
  const [isDeleteRequirementModalOpen, setIsDeleteRequirementModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const filterOptions = useMemo(() => {
    return {
      state: { name: 'State', items: states.map((state) => state.name), abbr: states.map((state) => state.abbreviation) },
      season: { name: 'Season', items: seasons.map((season) => season.name) },
      filters: { name: 'Filters' },
      download: { name: 'Download' }
    }
  }, [seasons, states])

  const selectedState = useMemo(() => states.find((state) => state.abbreviation === filter.state), [filter.state, states])

  const selectedSeason = useMemo(() => seasons.find((season) => season.name === filter.season), [filter.season, seasons])

  const loadData = async () => {
    setIsLoading(true)
    const response = await fetchChecklists({
      state_abbr: selectedState?.abbreviation ?? undefined,
      season_id: selectedSeason?.id ?? undefined,
      search: search || undefined,
      sortBy: sort.name || undefined,
      orderBy: sort.name ? (sort.direction === 1 ? 'asc' : 'desc') : undefined,
      per_page: pageSize,
      page: currentPage,
    })

    setChecklists(response.result.checklists)
    setChecklistsTotal(response._metadata.pagination.total)
    setIsLoading(false)
  }

  useEffect(() => {
    loadInitialValues()
  }, [])

  useEffect(() => {
    if (currentPage !== 1)
      setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState, selectedSeason, search])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort])

  const loadInitialValues = async () => {
    const statesResponse = await fetchStates()
    const fetchSeasonsResponse = await fetchSeasons()

    setStates(statesResponse.result.states)
    setSeasons(fetchSeasonsResponse.result.seasons)
  }

  const onChangeFilter = (value: Record<string, string>) => {
    setFilter(value)
  }

  const onHandleSort = (name: string) => {
    setSort({ name, direction: sort.name === name ? -sort.direction : 1 })
  }

  const handleDownload = () => {
    
  }

  const handleAddRequirement = () => {
    setIsRequirementModalOpen(true)
  }

  const handleDeleteChecklist = async (checklist: Checklist) => {
    setSelectedChecklist(checklist)
    setIsDeleteRequirementModalOpen(true)
  }

  const handleEditChecklist = async (checklist: Checklist) => {
    const response = await fetchChecklistAttachments(checklist.id)
    setSelectedChecklist(checklist)
    setSelectedChecklistAttachments(response.result.attachments)
    setIsRequirementModalOpen(true)
  }

  const handleCancelRequirementModal = () => {
    setSelectedChecklist(null)
    setSelectedChecklistAttachments([])
    setIsRequirementModalOpen(false)
  }

  const handleSubmitRequirementModal = ({ success, message }: { success: boolean, message: string }) => {
    setIsRequirementModalOpen(false)
    setSelectedChecklist(null)
    setSelectedChecklistAttachments([])
    setToast({
      type: success ? 'success' : 'danger',
      message
    })
    loadData()
  }

  const handleHideShowChecklists = (checklistId: number, isHidden: boolean) => {
    const tempChecklists = [...checklists]
    for (const checklist of tempChecklists) {
      if (checklist.id === checklistId) {
        checklist.hidden = isHidden
      }
    }

    setChecklists(tempChecklists)
  }

  return (
    <div className="flex flex-col h-0 grow overflow-hidden p-3">
      <div className="flex justify-between items-center h-[70px] px-3">
        <h1 className="text-[32px] leading-10">Requirements</h1>
        <Filters
          options={filterOptions}
          onChange={(value) => onChangeFilter(value)}
          onDownload={handleDownload}
        />
      </div>
      <div className="flex flex-col flex-1 px-3 pb-3 overflow-hidden">
        <TableHeader
          search={search}
          setSearch={setSearch}
          addLabel="Requirements"
          addNewModalOpen={handleAddRequirement}
        />
        <div className="flex flex-col overflow-hidden border-l border-r border-gray-200">
          <div className="flex flex-none bg-gray-50 border-b border-gray-200">
            <div
              className={`${tHeadClassName} ${sort.name === 'state' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('state')}
            >
              <span>State</span>
              <Direction sort={sort.name === 'state' ? sort.direction : 0} />
            </div>
            <div
              className={`${tHeadClassName} ${sort.name === 'county' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('county')}
            >
              <span>County</span>
              <Direction sort={sort.name === 'county' ? sort.direction : 0} />
            </div>
            <div
              className={`${tHeadClassName} ${sort.name === 'municipality' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('municipality')}
            >
              <span>Municipality</span>
              <Direction sort={sort.name === 'municipality' ? sort.direction : 0} />
            </div>
            <div
              className={`${tHeadClassName} ${sort.name === 'created' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('created')}
            >
              <span>Created</span>
              <Direction sort={sort.name === 'created' ? sort.direction : 0} />
            </div>
            <div
              className={`${tHeadClassName} ${sort.name === 'created' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('priority')}
            >
              <span>Priority</span>
              <Direction sort={sort.name === 'priority' ? sort.direction : 0} />
            </div>
            <div className={`${tHeadClassName} justify-center`}>
              <span>Attachment</span>
            </div>
            <div className="flex-1 text-right text-xs text-gray-600 uppercase tracking-[0.03em] p-3">Actions</div>
          </div>
          <div className="flex flex-col flex-auto overflow-y-auto no-scrollbar">
            {isLoading && (
              <div className="flex w-full h-40 items-center justify-center">
                <span className="text-lg">Loading data...</span>
              </div>
            )}
            {!isLoading && checklists.length === 0 && (
              <div className="flex w-full h-40 items-center justify-center">
                <span className="text-lg">No data</span>
              </div>
            )}
            {!isLoading && checklists.length > 0 && checklists.map((checklist) => (
              <div
                key={checklist.id}
                className={`flex items-center bg-white border-b border-gray-200 cursor-pointer last:border-none ${checklist.hidden ? 'hidden' : ''}`}
              >
                <div className="flex-1 px-4 py-5 text-sm text-gray-600 capitalize">{checklist.state?.name}</div>
                <div className="flex-1 px-4 py-5 text-sm text-gray-600 capitalize">{checklist.county?.name}</div>
                <div className="flex-1 px-4 py-5 text-sm text-gray-600 capitalize">{checklist.municipality?.name}</div>
                <div className="flex-1 px-4 py-5 text-sm text-gray-600">{moment(checklist.created_at).format(formatDate.FORMAT_DISPLAY)}</div>
                <div className="flex items-center gap-3 flex-1 px-4 py-5 text-sm text-[#000000]">
                  {checklist.priority === 'low' && <AptiveIcon className="w-[16px] h-[16px] fill-[#0EA5E9] stroke-none" icon="chevronDown" isFilled />}
                  {checklist.priority === 'medium' && <AptiveIcon className="w-[16px] h-[16px] fill-[#F59E0B] stroke-none" icon="menuAlt4" isFilled />}
                  {checklist.priority === 'high' && <AptiveIcon className="w-[16px] h-[16px] fill-[#F05252] stroke-none" icon="chevronUp" isFilled />}
                  <span>{capitalize(checklist.priority)}</span>
                </div>
                <div className="flex items-center justify-center gap-4 flex-1 px-4 py-5 text-sm text-gray-600">
                  <span>{checklist.attachments_count}</span>
                  <PaperClipSVG className="[&>path]:hover:stroke-[#1f2937]" />
                </div>
                <div className="flex items-center justify-end gap-4 flex-1 px-4 py-5 text-right">
                  <AptiveIcon
                    className="w-[20px] h-[20px] fill-none stroke-[#9CA3AF] hover:stroke-[#1f2937]"
                    icon="pencilAlt"
                    onClick={() => handleEditChecklist(checklist)}
                  />
                  <AptiveIcon
                    className="w-[20px] h-[20px] fill-none stroke-[#9CA3AF] hover:stroke-[#1f2937]"
                    icon="trash"
                    onClick={() => handleDeleteChecklist(checklist)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <TableFooter
          page={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalResults={checklistsTotal}
        />
      </div>
      {isRequirementModalOpen && (
        <ChecklistModal
          isOpen={isRequirementModalOpen}
          checklist={selectedChecklist}
          checklistAttachments={selectedChecklistAttachments}
          states={states}
          onCancel={handleCancelRequirementModal}
          onSubmit={handleSubmitRequirementModal}
        />
      )}
      <DeleteChecklistModal
        isOpen={isDeleteRequirementModalOpen}
        onClose={() => setIsDeleteRequirementModalOpen(false)}
        onHideShow={handleHideShowChecklists}
        checklist={selectedChecklist}
        refresh={loadData}
      />
    </div>
  )
}

export default RequirementsView
