import { useState, useEffect, FC } from 'react'

import { Navbar, Navbars, AptiveIcon } from '@aptive-env/storybook'
import DetailHeader from 'pages/SalesRepresentatives/ViewDetail/DetailHeader'
import DetailPersonalInfo from 'pages/SalesRepresentatives/ViewDetail/DetailPersonalInfo'
import DetailLicensing from 'pages/SalesRepresentatives/ViewDetail/DetailLicensing'
import DetailNotes from 'pages/SalesRepresentatives/ViewDetail/DetailNotes'
import LicenseModal from 'pages/SalesRepresentatives/License'
import DeleteLicenseModal from 'pages/SalesRepresentatives/DeleteLicense'

import { SalesRepresentative, License, Attachment, State } from 'interface'

import {
  fetchProfile,
  fetchStates,
  fetchLicense,
  fetchLicenseAttachments,
} from 'services'

interface IProfileViewProps {
  className?: string;
}

const ProfileView: FC<IProfileViewProps> = ({ className }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [profile, setProfile] = useState<SalesRepresentative | null>(null)
  const [states, setStates] = useState<State[]>([])
  const [search, setSearch] = useState('')
  const [licenses, setLicenses] = useState<License[]>([])
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false)
  const [isDeleteLicenseModalOpen, setIsDeleteLicenseModalOpen] = useState(false)
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null)
  const [selectedLicenseAttachments, setSelectedLicenseAttachments] = useState<Attachment[]>([])

  const onLicenseAdd = () => {
    setSelectedLicense(null)
    setIsLicenseModalOpen(true)
  }

  const onLicenseEdit = async (license: License) => {
    const licensesResponse = await fetchLicense(license.license_id)
    const uploadedAttachment = await fetchLicenseAttachments(license.license_id)
    
    setSelectedLicense(licensesResponse.result.license)
    setSelectedLicenseAttachments(uploadedAttachment.result.attachments)
    setIsLicenseModalOpen(true)
  }

  const onLicenseDelete = (license: License) => {
    setSelectedLicense(license)
    setIsDeleteLicenseModalOpen(true)
  }

  const fetchInitialValues = async () => {
    const [profileResponse, statesResponse] = await Promise.all([
      fetchProfile(),
      fetchStates(),
    ])

    setProfile(profileResponse.result.rep)
    setStates(statesResponse.result.states)
    setLicenses(profileResponse.result.licenses)
  }

  const handleHideShowLicense = (licenseId: number, isHidden: boolean) => {
    const tempLicenses = [...licenses]
    for (const license of tempLicenses) {
      if (license.license_id === licenseId) {
        license.hidden = isHidden
      }
    }
    setLicenses(tempLicenses)
  }

  useEffect(() => {
    fetchInitialValues()
  }, [])
  
  return (
    <div className={`relative flex flex-col grow h-0 ${className ?? ''}`}>
      <div className="absolute left-0 top-0 right-0 bottom-0 flex flex-col bg-white">
        <div className="border-b border-gray-200 px-6 py-5">
          <a
            href="/"
            className="flex items-center gap-4 cursor-pointer w-fit"
          >
            <AptiveIcon
              className="w-[24px] h-[24px] stroke-[#9CA3AF] fill-none"
              icon="arrowCircleLeft"
            />
            <span className="font-inter text-sm text-gray-600">Back</span>
          </a>
        </div>
        <div className="flex flex-col grow overflow-hidden">
          <div className="flex justify-center px-6 pt-6 pb-2 bg-gray-100 border-b border-gray-200">
            <DetailHeader
              profile={profile}
              className="w-[660px] pr-0"
            />
          </div>
          <div className="flex justify-center bg-white border-b border-gray-200">
            <Navbars className='gap-4 w-[660px] border-none' defaultValue={0}>
              <Navbar className='px-0 py-[23px]' title='Personal Info' onClick={() => setActiveTab(0)} />
              <Navbar className='px-0 py-[23px]' title='Licensing' onClick={() => setActiveTab(1)} />
              <Navbar className='px-0 py-[23px]' title='Notes' onClick={() => setActiveTab(2)} />
            </Navbars>
          </div>
          <div className="flex justify-center bg-gray-50 p-6 grow overflow-hidden">
            <div className="bg-white rounded-lg w-[660px] overflow-auto no-scrollbar">
              {activeTab === 0 && <DetailPersonalInfo />}
              {activeTab === 1 && 
                <DetailLicensing
                  licenses={licenses}
                  states={states}
                  search={search}
                  onSearch={setSearch}
                  onAdd={onLicenseAdd}
                  onEdit={onLicenseEdit}
                  onDelete={onLicenseDelete}
                />
              }
              {activeTab === 2 && <DetailNotes />}
            </div>
          </div>
        </div>
      </div>

      {isLicenseModalOpen && (
        <LicenseModal
          isOpen={isLicenseModalOpen}
          onClose={() => setIsLicenseModalOpen(false)}
          license={selectedLicense}
          salesRepresentative={profile}
          setSalesRepresentativeLicenses={setLicenses}
          selectedLicenseAttachments={selectedLicenseAttachments}
          states={states}
        />
      )}

      <DeleteLicenseModal
        isOpen={isDeleteLicenseModalOpen}
        onClose={() => setIsDeleteLicenseModalOpen(false)}
        onHideShow={handleHideShowLicense}
        license={selectedLicense}
        salesRepresentative={profile}
      />
    </div>
  )
}

export default ProfileView
