import { AptiveIcon, Avatar, Badge, Brand, Button, Header, Navbar, Navbars } from '@aptive-env/storybook'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const MainHeader: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const getDefaultNav = () => {
    switch (pathname) {
    case '/company-info':
      return 1
    case '/license-applications':
      return 2
    case '/requirements':
      return 3
    case '/sales-representatives':
      return 4
    default:
      return 0
    }
  }


  return (
    <>
      <Header className="pt-[15px] px-[32px] pb-[16px] leading-none flex justify-between border-b-[1px]">
        <Brand
          className='cursor-pointer'
          imgSrc="/assets/image/logos/aptive-wordmarks.svg"
          label="aptive-wordmarks"
          onClick={() => navigate('/')}
        />
        <div className='flex flex-row items-center'>
          <div className="relative">
            <Button
              aria-haspopup="true"
              aria-label="Dropdown Summer 2022"
              icon="chevronDown"
              isFilled
              onClick={() => { }}
              position="right"
              variant="neutral"
            >
              Summer 2022
            </Button>
          </div>
          <div className='my-auto ml-[16px] mr-[4px]'>
            <AptiveIcon
              className="w-[24px] h-[24px] stroke-[#9CA3AF] fill-none"
              icon="bell"
            />
          </div>
          <div className="relative">
            <Button
              aria-haspopup="true"
              aria-label="User Profile Dropdown"
              icon="chevronDown"
              isFilled
              onClick={() => { }}
              position="right"
              variant="neutral"
              className='py-[7px] border-0'
            >
              <Avatar
                imgSrc="/assets/image/avatar/user.png"
                label="User"
                size="default"
                aria-haspopup="true"
                aria-label="User Avatar"
              />
              <Badge className="ml-[11px] mr-[5px]" color="success">
                Admin
              </Badge>
            </Button>
          </div>
        </div>
      </Header>
      <Navbars className='px-[32px]' defaultValue={getDefaultNav()}>
        <Navbar title='Dashboard' onClick={() => navigate('/')}></Navbar>
        <Navbar title='Company Info' onClick={() => navigate('/company-info')}></Navbar>
        <Navbar title='License applications' onClick={() => navigate('/license-applications')}></Navbar>
        <Navbar title='Requirements' onClick={() => navigate('/requirements')}></Navbar>
        <Navbar title='Sales representatives' onClick={() => navigate('/sales-representatives')}></Navbar>
      </Navbars>
    </>
  )
}

export default MainHeader
